'use strict';

import { h, Component, createRef, Fragment } from 'preact';
import { ColumnSlice, TableSlice, Range } from './csv';
import * as Plot from "@observablehq/plot";
import { PlotSettings, PortStatus } from './state';

// Colors generated by https://observablehq.com/@skybrian/serialviz-colors

// category10 (brighter)
const litColorRange = [
    "#258ed7",
    "#ff9811",
    "#35bf35",
    "#ff2f30",
    "#b17be2",
    "#a7675a",
    "#ff8ee8",
    "#989898",
    "#e1e229",
    "#1be3f7"
];

// category10 (darker)
const darkColorRange = [
    "#124669",
    "#7d4a08",
    "#1a5e1a",
    "#7d1718",
    "#573c6f",
    "#52322c",
    "#7d4672",
    "#4a4a4a",
    "#6e6f14",
    "#0d6f79"
];

const maxUnselectedViews = 4;

export interface PlotProps {
    status: PortStatus;
    table: TableSlice;
    settings: PlotSettings;
    windowChanges: number;
    toggleColumn: (name: string) => void;
    pan: (delta: number) => void;
}

export class PlotView extends Component<PlotProps> {
    plotElt = createRef<HTMLDivElement>();

    colorFor(columnName: string, options?: { lit?: boolean }): string {
        const lit = options?.lit ?? true;
        for (let i = 0; i < litColorRange.length; i++) {
            if (this.props.table.columnNames[i] == columnName) {
                return lit ? litColorRange.at(i) : darkColorRange.at(i);
            }
        }
        return "black";
    }

    get xDomain(): [number, number] {
        const r = this.props.settings.range;
        return [r.start, r.end];
    }

    mainPlot(parent: HTMLDivElement) {
        parent.textContent = "";

        let width = parent.offsetWidth;
        width = !width ? 640 : width;
        let height = parent.offsetHeight;

        const table = this.props.table;
        const rowsScrolled = this.xDomain[0];

        let marks = [];
        if (table.rows.length >= 2) { // avoid high cardinality warning in Plot.
            for (let col of table.columns) {
                if (this.props.settings.columnStates.get(col.name) == "top") {
                    const start = col.range.start;
                    marks.push(Plot.lineY(col.values, { x: (_, i) => i + start, stroke: this.colorFor(col.name) }));
                }
            }
        }

        parent.appendChild(Plot.plot({
            width: width,
            height: height,
            marks: marks,
            marginTop: 60,
            marginLeft: 60,
            x: {
                domain: this.xDomain,
                axis: "top"
            },
            y: {
                nice: true,
                zero: false
            }
        }));
    }

    componentDidMount() {
        this.mainPlot(this.plotElt.current);
    }

    componentDidUpdate() {
        this.mainPlot(this.plotElt.current);
    }

    makeToggleButton = (name: string) => {
        const state = this.props.settings.columnStates.get(name);
        const classes = "swatch" + (state == "top" ? " swatch-lit" : (state == "bottom" ? " swatch-bottom" : ""));
        return <div class="swatch-button">
            <span class={classes} style={`background-color: ${this.colorFor(name, { lit: state != "hidden" })}`}> </span>
            <button class="pure-button"
                onClick={() => this.props.toggleColumn(name)}
            >{name}</button>
        </div>
    };

    bottomColumns(): ColumnSlice[] {
        const states = this.props.settings.columnStates;
        return this.props.table.columns.filter((c) => states.get(c.name) == "bottom");
    }

    render() {
        return <div class="plot-view">
            <div class="plot-main-buttons" role="group">
                {this.props.table.columnNames.map((name) => this.makeToggleButton(name))}
            </div>
            <div class="plot-main">
                {this.props.status == "reading" ? "" : <Slider bounds={this.props.settings.bounds} thumb={this.props.settings.range} onSlide={this.props.pan} />}
                <div class="plot-main-graph" ref={this.plotElt} />
            </div>
            {this.bottomColumns().map((col) =>
                <BottomPlotView
                    column={col}
                    xDomain={this.xDomain}
                    color={this.colorFor(col.name)}
                />)}
        </div>;
    }

    renderUnselectedColumn(name: string) {

        return <>
            <div class="plot-unselected-label">${name}</div>
        </>
    }
}

interface SliderProps {
    bounds: Range;
    thumb: Range;
    onSlide: (delta: number) => void;
}

class Slider extends Component<SliderProps> {
    elt = createRef<HTMLDivElement>();
    prevX = null as number;

    beginSliding = (e: PointerEvent) => {
        this.prevX = e.clientX;
        this.elt.current.setPointerCapture(e.pointerId);
        this.elt.current.onpointermove = this.slide;
    }

    stopSliding = (e: PointerEvent) => {
        this.prevX = null;
        this.elt.current.releasePointerCapture(e.pointerId);
        this.elt.current.onpointermove = null;
    }

    slide = (e: PointerEvent) => {
        const sliderWidth = this.elt.current.clientWidth;
        const boundsWidth = this.props.bounds.length;
        if (sliderWidth == 0 || boundsWidth == 0 || this.prevX == null) return;

        const delta = Math.round((e.clientX - this.prevX) * boundsWidth / sliderWidth);
        if (delta != 0) {
            this.props.onSlide(delta);
            this.prevX += delta * sliderWidth / boundsWidth;
        }
    }

    render() {
        const bounds = this.props.bounds;
        const thumb = this.props.thumb;

        const leftMarginPercent = 100 * (thumb.start - bounds.start) / bounds.length;
        const thumbPercent = 100 * thumb.length / bounds.length;

        return <div class="slider" ref={this.elt}
            onPointerDown={this.beginSliding}
            onPointerUp={this.stopSliding}>
            <div class="thumb" style={`margin-left: ${leftMarginPercent}%; width: ${thumbPercent}%`}> </div>
        </div>
    }
}

class BottomPlotView extends Component<{ column: ColumnSlice, xDomain: [number, number], color: string }> {
    plotElt = createRef<HTMLDivElement>();
    lastIndex = null;

    bottomPlot(parent: HTMLDivElement) {
        parent.textContent = "";

        let width = parent.offsetWidth;
        width = !width ? 640 : width;
        const height = parent.offsetHeight;

        const marks = [];
        const col = this.props.column;
        if (col.values.length >= 2) { // avoid high cardinality warning in Plot.
            const start = col.range.start;
            marks.push(Plot.lineY(col.values, { x: (_, i) => i + start, stroke: this.props.color }));
        }

        parent.appendChild(Plot.plot({
            width: width,
            height: height,
            marginLeft: 60,
            marks: marks,
            x: {
                domain: this.props.xDomain,
                axis: null,
            },
            y: {
                nice: true,
                zero: false,
            }
        }));
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        this.bottomPlot(this.plotElt.current);
    }

    render() {
        return <>
            <div class="bottom-plot-label" key={"label-" + this.props.column.name}>
                <div>{this.props.column.name}</div>
            </div>
            <div class="bottom-plot-view"
                key={this.props.column.name}
                ref={this.plotElt}>
            </div>
        </>
    }
}
